<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="text-muted font-13 mb-0"></p>
              <div>
                <b-card-code >
                  <validation-observer ref="simpleRules">
                    <form @submit.prevent="submitForm">
                      <b-row>
                        <b-col md="4">
                          <b-form-group>
                            <label>Select Date</label>
                            <flat-pickr class="form-control"
                              :config="{
                              dateFormat: 'd/m/Y',
                              mode: 'range'}"
                              style="background-color: transparent"
                              placeholder="Select Date"
                              @input="getdata($event)"/>
                          </b-form-group>
                        </b-col>

                      </b-row>
                      <div class="d-flex justify-content-end">
                        <b-button  variant="primary" class="ml-2" @click="searchData">
                          <feather-icon icon="SearchIcon" class="mr-50" />
                          <span class="align-middle">Search</span>
                        </b-button>
                      </div>
                    </form>
                  </validation-observer>
                </b-card-code>
                <report-table
                  :data="data"
                  :columns="fields"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from '../../../components/ReportTable.vue'
import axios from '@/components/axios'
import BCardCode from '@core/components/b-card-code'
import flatPickr from 'vue-flatpickr-component'
import GoodTableColumnSearch from '../../../components/GoodTableColumnSearch.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Form from '@/components/form/Form.vue'
import {
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton
} from 'bootstrap-vue'
const baseApi = process.env.VUE_APP_APIENDPOINT
import vSelect from 'vue-select'
export default {
  components: {
    ReportTable,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    GoodTableColumnSearch
  },
  data () {
    return {
      startdate: '',
      enddate: '',
      result: '',
      data: '',
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken:localStorage.getItem('accessToken'),
      fields: [
        { field: 'id', label: 'S.R. No', hidden:true, filterOptions: {enabled: true, placeholder: 'Search '}},
        { field: 'name', label: 'Customer Name', filterOptions: {enabled: true, placeholder: 'Search '} },
        { field: 'email', label: 'Email', filterOptions: {enabled: true, placeholder: 'Search '} },
        { field: 'mobile', label: 'Mobile', filterOptions: {enabled: true, placeholder: 'Search '} },
        { field: 'addressline1', label: 'Address1', filterOptions: {enabled: true, placeholder: 'Search '} },
        { field: 'addressline2', label: 'Address2', filterOptions: {enabled: true, placeholder: 'Search '} },
        { field: 'city', label: 'City', filterOptions: {enabled: true, placeholder: 'Search '} },
        { field: 'state', label: 'State', filterOptions: {enabled: true, placeholder: 'Search '} },
        { field: 'contactperson', label: 'Contact Person', filterOptions: {enabled: true, placeholder: 'Search '} },
        { field: 'contactpermobile', label: 'Contact Number', filterOptions: {enabled: true, placeholder: 'Search '} }
      ]
    }
  },
  methods: {
    getdata (selectedDates) {
      this.result = selectedDates.split(' to ')
      this.startdate = this.result[0]
      this.enddate = this.result[1]
    },
    async searchData () {
      const data = {
        startdate: this.startdate,
        enddate: this.enddate
      }
      await axios({
        method:'POST',
        headers:{
          'Content-Type':'application/json',
          'Authorization': `Bearer ${this.accessToken}`
        },
        data:JSON.stringify(data),
        url:`${this.baseApi}/customerreport`
      })
        .then((response) => {
          this.data = response.data.data
        })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
